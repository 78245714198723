import React from 'react'
import { Link } from 'react-router-dom'

export const About = () => {
  return (
    <>
      
      <div className="container">

            <h1 className="py-5 text-center">Sehory App</h1>

            <p className='lead'>Sehory is a smartphone app as well as a website that manages the inventory of your shops and warehouses online. In addition, it can also produce GST Invoice bills, progress report and graphs in a second.</p>

            <p>
              This is the product released by <Link to="/company-page">GujMCQ Developers</Link>.
            </p>


      </div>

    </>
  )
}
