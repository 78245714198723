import React from 'react'
import appLogoForHeader from '../assets/logo-small.png'
import { Link } from 'react-router-dom'

export const Header = () => {

  const appLogoStyle = {
    height: '64px'
  }

  return (

    <nav className="navbar navbar-expand-lg border-bottom border-light-subtle">
  <div className="container-fluid">
    <Link className="navbar-brand" to="/">
      <img src={appLogoForHeader} style={appLogoStyle} alt="Logo" />
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="app-features">Features</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="download">Download</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="faqs">FAQs</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="about">About Us</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="contact">Contact</Link>
        </li>
      </ul>
    </div>
  </div>
</nav>
  )
}
