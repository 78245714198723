import React from 'react'

export const RefundPolicy = () => {
  return (
    <div className='container p-5'>

      <p className="h4">
      RETURN POLICY
      </p>

      <p>
      Last updated March 11, 2024
      </p>

      <p className="h5">
      REFUNDS
      </p>

      <p>
      All sales are final and no refund will be issued. However, if you've made payment, the money is deducted but the plan is not activated then you can contact us through payments@sehory.com. In such case, we'll try to activate the plan upon successful verification of details. If we get fail to activate the plan, we'll refund the 100% of amount deducted.
      </p>
      
      <p className="h5">
      QUESTIONS
      </p>

      <p>
      If you have any questions concerning our return policy, please contact us at:
      </p>

      <p>
      payments@sehory.com
      </p>

    </div>
  )
}
