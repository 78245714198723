import React from "react";
import { Route, Routes } from "react-router-dom";
import { Welcome } from "./Pages/Welcome";
import { About } from "./Pages/Essentials/About";
import { Contact } from "./Pages/Essentials/Contact";
import { CookiePolicy } from "./Pages/Essentials/CookiePolicy";
import { Download } from "./Pages/Essentials/Download";
import { FAQs } from "./Pages/Essentials/FAQs";
import { PrivacyPolicy } from "./Pages/Essentials/PrivacyPolicy";
import { RefundPolicy } from "./Pages/Essentials/RefundPolicy";
import { SubscriptionPlans } from "./Pages/Essentials/SubscriptionPlans";
import { TermsOfService } from "./Pages/Essentials/TermsOfService";
import { Disclaimer } from "./Pages/Essentials/Disclaimer";
import { CompanyPage } from "./Pages/Essentials/CompanyPage";
import { Features } from "./Pages/Essentials/Features";
import { AccountDelete } from "./Pages/Essentials/AccountDelete";

export const Main = () => {
  return (
    <React.Fragment>
      <main>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />
          <Route path="disclaimer" element={<Disclaimer />} />
          <Route path="download" element={<Download />} />
          <Route path="faqs" element={<FAQs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="refund-policy" element={<RefundPolicy />} />
          <Route path="subscription-plans" element={<SubscriptionPlans />} />
          <Route path="tos" element={<TermsOfService />} />
          <Route path="company-page" element={<CompanyPage />} />
          <Route path="app-features" element={<Features />} />
          <Route path="account-delete" element={<AccountDelete />} />
        </Routes>
      </main>
    </React.Fragment>
  );
};
