import React from 'react'

export const Download = () => {
  return (
    <div className="container p-5">
      
      <p className="h2 text-center">Download the app</p>

      <div className="container-xxl text-center pb-5">
      <a href='https://play.google.com/store/apps/details?id=com.sehory&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' className="img-fluid" width="280px" src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
      </div>

    </div>
  )
}
