import React, { Component } from "react";
import "./App.css";
import "./assets/common_style.css"
import { Header } from "./MyComponents/Header";
import { Main } from "./MyComponents/Main";
import { Footer } from "./MyComponents/Footer";

export const App = () => {
  return (
    <React.Fragment>
      <div className="main_container">
        <Header />
        <Main />
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default App;
