import React from "react";
import { BarcodeIcon, CheckCircleIcon, DownloadIcon, GoogleCloudIcon, InventoryIcon, LineChartIcon, MultiUserIcon, PDFIcon, RepeatIcon, StarIcon, StoreIcon } from "../../assets/icons/CheckCircle";
import welcomeImage1 from "../../assets/welcome-1-mystockregister.jpg";
import welcomeImage2 from "../../assets/welcome-2-mystockregister.jpg";
import { themeScheme } from "../Color";

export const Welcome = () => {

  const ratingContainerDivStyle = {
    backgroundColor: themeScheme.purpleTheme.surface
  }

  const benefitDivStyle = {
    backgroundColor: themeScheme.purpleTheme.primary
  }

  return (
    <>
      <div className="container-xxl" style={ratingContainerDivStyle}>
        <div className="row align-items-center p-xl-5">
          <div className="col">
            <h1 className="lh-base fw-medium fs-3 lead">
              Inventory Management & GST Billing Smartphone app for you stores
              or warehouses
            </h1>

            <table className="table mt-4 align-middle table-borderless fs-6 lead">
              <tbody>
                <tr>
                  <td className="align-middle">
                    <CheckCircleIcon fill="#198754" /> Stock Register
                  </td>
                  <td className="align-middle">
                    <CheckCircleIcon fill="#198754" /> Inventory Bills
                  </td>
                </tr>
                <tr>
                  <td>
                    <CheckCircleIcon fill="#198754" /> GST & Non-GST Billing
                  </td>
                  <td>
                    <CheckCircleIcon fill="#198754" /> Summary of
                    Sale/Profile/Loss
                  </td>
                </tr>
                <tr>
                  <td>
                    <CheckCircleIcon fill="#198754" /> Unlimited
                    Stores/Warehouses
                  </td>
                  <td>
                    <CheckCircleIcon fill="#198754" /> Generate Reports and
                    Graphs
                  </td>
                </tr>
                <tr>
                  <td>
                    <CheckCircleIcon fill="#198754" /> PDF, EXCEL, HTML, CSV+
                    reports
                  </td>
                  <td>
                    <CheckCircleIcon fill="#198754" /> Role-based Staff
                    Management
                  </td>
                </tr>
                <tr>
                  <td>
                    <CheckCircleIcon fill="#198754" /> Unlimited Parties
                  </td>
                  <td>
                    <CheckCircleIcon fill="#198754" /> Day & Dark Theme
                  </td>
                </tr>
                <tr>
                  <td>
                    <CheckCircleIcon fill="#198754" /> Animated & Easy UI
                  </td>
                  <td>
                    <CheckCircleIcon fill="#198754" /> Secure Data Handling
                  </td>
                </tr>
                <tr>
                  <td>
                    <CheckCircleIcon fill="#198754" /> 10+ Invoice Formats
                  </td>
                  <td>
                    <CheckCircleIcon fill="#198754" /> 20+ languages
                  </td>
                </tr>
                <tr>
                  <td>
                    <CheckCircleIcon fill="#198754" /> Google Cloud Served Data
                  </td>
                  <td>
                    <CheckCircleIcon fill="#198754" /> Data Fully-Encrypted in
                    Transit
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col text-center">
            <img
              src={welcomeImage1}
              style={{ height: "480px" }}
              alt="My Stock Register android app home page screenshot"
            />
            <img
              src={welcomeImage2}
              style={{ height: "480px" }}
              alt="A screenshot of records page of My Stock Register android app"
            />
          </div>
        </div>
      </div>

      <div element-name="ratingContainerDiv" className="container-xxl" style={ratingContainerDivStyle}>
        
        <div className="row p-5 align-middle">

          <div className="col text-center">
            <StarIcon fill={themeScheme.purpleTheme.primary} class="display-2 mb-4" />
            <br />
            <span className="display-6">4.7/5</span>
            <br />
            <span className="fs-6 lead">Google Play Store Ratings</span>
          </div>
          
          <div className="col text-center">
            <DownloadIcon fill={themeScheme.purpleTheme.primary} class="display-2 mb-4" />
            <br />
            <span className="display-6">125K+</span>
            <br />
            <span className="fs-6 lead">Active Users</span>
          </div>

          <div className="col text-center">
            <MultiUserIcon fill={themeScheme.purpleTheme.primary} class="display-2 mb-4" />
            <br />
            <span className="display-6">Multi-user</span>
            <br />
            <span className="fs-6 lead">Owner + Staff Members</span>
          </div>

          <div className="col text-center">
            <GoogleCloudIcon class="display-2 mb-4" />
            <br />
            <span className="display-6">100% Secure</span>
            <br />
            <span className="fs-6 lead">Data Stored in Google Cloud</span>
          </div>

        </div>

      </div>

      <div className="container my-5">

        <h2 className="text-center h1 d-block mb-5">It takes just 3 simple steps in a minute</h2>
        
        <div className="row">

          <div className="col-xs-3 col-md-4 align-middle">
            <span className="h1 align-middle d-block fw-bold">1</span><span className="d-block align-middle">Create an account just with a phone number + Google Login.</span>
          </div>

          <div className="col-xs-3 col-md-4 align-middle">
            <span className="h1 align-middle d-block fw-bold">2</span><span className="d-block align-middle">Create a new store and add products</span>
          </div>

          <div className="col-xs-3 col-md-4 align-middle">
            <span className="h1 align-middle d-block fw-bold">3</span><span className="d-block align-middle">Start touching inventory and bill entries. That's it!</span>
          </div>

        </div>

      </div>

      <div className="container-xxl text-center pb-5">
      <a href='https://play.google.com/store/apps/details?id=com.sehory&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' className="img-fluid" width="280px" src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
      </div>
      
      <div className="container-fluid" style={benefitDivStyle}>

        <h2 className="p-5 text-center display-6" style={{color: themeScheme.purpleTheme.onPrimary}}>Why Sehory?</h2>
        
        <div className="row mb-5 pb-5">

          <div className="col text-center">
            <StoreIcon fill={themeScheme.purpleTheme.onPrimary} class="display-2 mb-4" />
            <br />
            <span className="fs-2 fw-bold" style={{color: themeScheme.purpleTheme.onPrimary}}>Manage Multiple stores</span>
            <br />
            <span className="fs-5 lead mt-3 d-block" style={{color: themeScheme.purpleTheme.onPrimary}}>Add/Edit/Delete all of your shops, warehouses, and more</span>
          </div>

          <div className="col text-center">
            <InventoryIcon fill={themeScheme.purpleTheme.onPrimary} class="display-2 mb-4" />
            <br />
            <span className="fs-2 fw-bold" style={{color: themeScheme.purpleTheme.onPrimary}}>Easy Inventory Management</span>
            <br />
            <span className="fs-5 lead mt-3 d-block" style={{color: themeScheme.purpleTheme.onPrimary}}>
              Stock IN/OUT is made super-easy in just 3 steps
            </span>
          </div>

        </div>

        <div className="row mb-5 pb-5">

          <div className="col text-center">
            <PDFIcon fill={themeScheme.purpleTheme.onPrimary} class="display-2 mb-4" />
            <br />
            <span className="fs-2 fw-bold" style={{color: themeScheme.purpleTheme.onPrimary}}>Multi-format Report Export</span>
            <br />
            <span className="fs-5 lead mt-3 d-block" style={{color: themeScheme.purpleTheme.onPrimary}}>Export report in PDF, HTML, JSON, CSV, TEXT, and more</span>
          </div>

          <div className="col text-center">
            <LineChartIcon fill={themeScheme.purpleTheme.onPrimary} class="display-2 mb-4" />
            <br />
            <span className="fs-2 fw-bold" style={{color: themeScheme.purpleTheme.onPrimary}}>Visualize Progress with Graphs</span>
            <br />
            <span className="fs-5 lead mt-3 d-block" style={{color: themeScheme.purpleTheme.onPrimary}}>
              Create Bar, Line, Pie, and Donut Chart of your data
            </span>
          </div>

        </div>

        <div className="row pb-5">

          <div className="col text-center">
            <BarcodeIcon fill={themeScheme.purpleTheme.onPrimary} class="display-2 mb-4" />
            <br />
            <span className="fs-2 fw-bold" style={{color: themeScheme.purpleTheme.onPrimary}}>Barcode Scanner</span>
            <br />
            <span className="fs-5 lead mt-3 d-block" style={{color: themeScheme.purpleTheme.onPrimary}}>Scan Barcode within a Second with Built-in Scanner</span>
          </div>

          <div className="col text-center">
            <RepeatIcon fill={themeScheme.purpleTheme.onPrimary} class="display-2 mb-4" />
            <br />
            <span className="fs-2 fw-bold" style={{color: themeScheme.purpleTheme.onPrimary}}>Switch Store Smoothly</span>
            <br />
            <span className="fs-5 lead mt-3 d-block" style={{color: themeScheme.purpleTheme.onPrimary}}>
              You can jump to any store from anywhere in the app just by 1-click  
            </span>
          </div>

        </div>

      </div>

    </>
  );
};
