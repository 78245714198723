import React from "react"
import companyLogo from "../../../assets/company-loho.png"

export const CompanyPage = () => {
    return (
        <>

            <div className="container-md">

                <div className="text-center pt-5">
                    <img src={companyLogo} alt="GujMCQ Developers Logo" style={{width: '150px', height: '150px'}}/>
                </div>

                <h1 className="text-center py-5">
                    GujMCQ Developers
                </h1>

                <p>
                    We, GujMCQ Developers, is a mobile apps & games company who develop useful and entertaining apps along with games and publish it on the Google Play Store.
                </p>
                
                <p>
                    Till now, we have developed mobile apps for Android OS across categories such as
                    <ul>
                        <li>Business</li>
                        <li>Productivity</li>
                        <li>Tools</li>
                        <li>Education</li>
                        <li>Puzzle Game</li>
                    </ul>
                </p>

                <p>
                    You can find all of our apps & games on <a href="https://play.google.com/store/apps/dev?id=5663113699693290436" target="_blank">Google Play Store here</a>.
                </p>

                <p className="pb-5">Furthermore, <a target="_blank" href="https://www.linkedin.com/company/gujmcq-developers/">visit our LinkedIn profile</a>.</p>

            </div>
            
            

        </>
    )
}