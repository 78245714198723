import React from "react";
import { Link, NavLink } from "react-router-dom";
import { themeScheme } from "./Color";

export const Footer = () => {
  let footerStyle = {
    backgroundColor: "rgb(44,50,75)",
  };

  return (
    <React.Fragment>
      <footer className="" style={footerStyle}>
        <div className="container text-start my-5">
          <div className="row align-item-start">
            <div className="col">
              <ul className="list-group bg-transparent">
                <li className="list-group-item bg-transparent border-0 active fw-bolder my-1">
                  Company
                </li>
                <li className="list-group-item bg-transparent border-0 active my-1">
                <NavLink className="nav-link" to="/about">About</NavLink>
                </li>
              </ul>
            </div>

            <div className="col">
              <ul className="list-group bg-transparent">

                <li className="list-group-item bg-transparent border-0 active fw-bolder my-1">
                  Legal
                </li>

                <li className="list-group-item bg-transparent border-0 active my-1">
                  FAQs
                </li>
                
                <li className="list-group-item bg-transparent border-0 active my-1">
                  <NavLink className="nav-link" to="/privacy-policy">Privacy Policy</NavLink>
                </li>
                
                <li className="list-group-item bg-transparent border-0 active my-1">
                  <NavLink className="nav-link" to="/disclaimer">Disclaimer</NavLink>
                </li>

                <li className="list-group-item bg-transparent border-0 active my-1">
                  <NavLink className="nav-link" to="/tos">Terms of Service</NavLink>
                </li>
                
                <li className="list-group-item bg-transparent border-0 active my-1">
                  <NavLink className="nav-link" to="/cookie-policy">
                  Cookie Policy
                  </NavLink>
                </li>

                <li className="list-group-item bg-transparent border-0 active my-1">
                  <NavLink className="nav-link" to="/refund-policy">Refund Policy</NavLink>
                </li>

              </ul>
            </div>

            <div className="col">
              <ul className="list-group bg-transparent">
                <li className="list-group-item bg-transparent border-0 active fw-bolder my-1">
                  Quick Contact
                </li>
                <li className="list-group-item bg-transparent border-0 active my-1">
                  642, Muktidham Society, <br />Opp. Sardar Complex, <br />Punagam to Bombay
                  Market Road, Surat, GJ <br /> India 395010.
                </li>
                <li className="list-group-item bg-transparent border-0 active my-1">
                  Email: <p>contact@sehory.com</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container">
            
                <hr />
            
                <p className="text-center pb-3 pt-5" style={{color: themeScheme.purpleTheme.onBackground}}>
                  This product is released and managed by GujMCQ Developers. <Link to="/company-page" style={{color: themeScheme.purpleTheme.onBackground}}>Learn more</Link>.
                  </p>

          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};
