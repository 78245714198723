import React from 'react'

export const AccountDelete = () => {
    return (
        <div className='container p-5'>

        <p className="h4">
            ACCOUNT DELETE
        </p>

        <p>
            To delete account, log-in into the app.<br/><br/>Go to Account {'->'} Account operations {'->'} Delete Account {'->'} Delete account.
        </p>

        </div>
    )
}