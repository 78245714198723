import React from 'react'

export const Contact = () => {
  return (
    
    <div className="container p-4">

        <p className="h2">
          Contact Us
        </p>

        <p>You can contact us by any of the following method:</p>

        <ul>
          <li>
            Email: <p>contact@sehory.com</p>
          </li>
        </ul>

    </div>

  )
}
