import React from "react"

import appThemeImage from "../../../assets/app-theme.png"
import featureBriefImage from "../../../assets/all-feature-list.png"
import stockTxnImage from "../../../assets/stock-txn.png"
import dailySummaryImage from "../../../assets/daily-summary.png"
import reportsAndGraphImage from "../../../assets/report-and-graphs.png"
import pdfInvoiceFormatsListImage from "../../../assets/pdf-invoice-formats.png"
import staffMgmtImage from "../../../assets/staff-mgmt.png";
import defaultValuesImage from "../../../assets/default-values-save-time.png"

export const Features = () => {

    return (
        <>

            <div className="container-md text-center">

                <h1 className="py-5">Features of Sehory App</h1>

                <p className="text-start">This app is not a financial service product. It works as an record-book for the inventory of your all stores and warehouses.</p>

                <p className="text-start">
                    You don't need to keep a paper-record-book to manage you stores and warehouses. Just use this app and it will handle everything like charm.
                </p>

                <p className="lead">
                    1. Feature Briefing
                </p>
                <img style={{width: '100%'}} className="pb-5" src={featureBriefImage} alt="Briefly listed features of My Stock Register app" />

                <p className="lead">
                    2. Day and Dark theme
                </p>
                <img style={{width: '100%'}} className="pb-5" src={appThemeImage} alt="Day and Dark theme feature of My Stock Register app." />

                <p className="lead">
                    3. Stock or Inventory Inward/Outward
                </p>
                <img style={{width: '100%'}} className="pb-5" src={stockTxnImage} alt="Stock Inward/Outward  feature of My Stock Register app." />

                <p className="lead">
                    4. Daily Summary & In/Out Records
                </p>
                <img style={{width: '100%'}} className="pb-5" src={dailySummaryImage} alt="Stock Inward/Outward records and daily summary feature of My Stock Register app." />

                <p className="lead">
                    5. Generate Fully Customized Reports & Graph
                </p>
                <img style={{width: '100%'}} className="pb-5" src={reportsAndGraphImage} alt="Fully customized report and graph feature of My Stock Register app." />

                <p className="lead">
                    6. 10+ Different Formats to Generate GST Invoice
                </p>
                <img style={{width: '100%'}} className="pb-5" src={pdfInvoiceFormatsListImage} alt="Different formats for Invoice generation feature of My Stock Register app." />

                <p className="lead">
                    7. Role-based Easy Staff Management
                </p>
                <img style={{width: '100%'}} className="pb-5" src={staffMgmtImage} alt="Role-based staff management feature of My Stock Register app." />


            </div>

        </>
    )

}