
import React from "react"

// Purple Theme

const purpleThemePrimary = 'rgb(111, 67, 192)';
const purpleThemeOnPrimary = 'rgb(255, 255, 255)';
const purpleThemePrimaryContainer = 'rgb(235, 221, 255)';
const purpleThemeOnPrimaryContainer = 'rgb(37, 0, 89)';

const purpleThemeError = 'rgb(187, 22, 20)';
const purpleThemeOnError = 'rgb(255, 255, 255)';
const purpleThemeErrorContainer = 'rgb(255, 218, 213)';
const purpleThemeOnErrorContainer = 'rgb(65, 0, 1)';

const purpleThemeSecondary = 'rgb(99, 91, 113)';
const purpleThemeOnSecondary = 'rgb(255, 255, 255)';
const purpleThemeSecondaryContainer = 'rgb(233, 222, 248)';
const purpleThemeOnSecondaryContainer = 'rgb(31, 24, 43)';

const purpleThemeTertiary = 'rgb(56, 102, 102)';
const purpleThemeOnTertiary = 'rgb(255, 255, 255)';
const purpleThemeTertiaryContainer = 'rgb(187, 236, 235)';
const purpleThemeOnTertiaryContainer = 'rgb(0, 32, 32)';

const purpleThemeSurface = 'rgb(235, 235, 235)';
const purpleThemeOnSurface = 'rgba(124, 120, 129, 255)';
const purpleThemeSurfaceVariant = 'rgb(255, 255, 255)';
const purpleThemeOnSurfaceVariant = 'rgb(28, 27, 31)';

const purpleThemeOutline = 'rgb(122, 117, 127)';
const purpleThemeOutlineVariant = 'rgb(202, 196, 207)';

const purpleThemeBackground = 'rgb(111, 67, 192)';
const purpleThemeOnBackground = 'rgb(255, 255, 255)';

const purpleThemeScheme = {
    
    primary: purpleThemePrimary,
    onPrimary: purpleThemeOnPrimary,
    primaryContainer: purpleThemePrimaryContainer,
    onPrimaryContainer: purpleThemeOnPrimaryContainer,

    secondary: purpleThemeSecondary,
    onSecondary: purpleThemeOnSecondary,
    secondaryContainer: purpleThemeSecondaryContainer,
    onSecondaryContainer: purpleThemeOnSecondaryContainer,

    error: purpleThemeError,
    onError: purpleThemeOnError,
    errorContainer: purpleThemeErrorContainer,
    onErrorContainer: purpleThemeOnErrorContainer,

    tertiary: purpleThemeTertiary,
    onTertiary: purpleThemeOnTertiary,
    tertiaryContainer: purpleThemeTertiaryContainer,
    onTertiaryContainer: purpleThemeOnTertiaryContainer,

    surface: purpleThemeSurface,
    onSurface: purpleThemeOnSurface,
    
    surfaceVariant: purpleThemeSurfaceVariant,
    onSurfaceVariant: purpleThemeOnSurfaceVariant,

    outline: purpleThemeOutline,
    outlineVariant: purpleThemeOutlineVariant,

    background: purpleThemeBackground,
    onBackground: purpleThemeOnBackground

}

const themeScheme = {
    purpleTheme: purpleThemeScheme
}

export {
    themeScheme
}